import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import "./Quiz.css";

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: 0,
      questions: [],
      score: 0,
      selectedAnswer: null,
      quizComplete: false,
      selectedLanguage: "AR", // Default language is Arabic
    };
  }

  async componentDidMount() {
    this.loadQuestions(this.state.selectedLanguage);
  }

  loadQuestions = async (language) => {
    try {
      let questionsFile = "/questions.json"; // Default to Arabic
      if (language === "EN") {
        questionsFile = "/questionsEn.json"; // Use English questions
      }

      const response = await fetch(questionsFile);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      this.setState({ questions: data });
    } catch (error) {
      console.error("Error fetching quiz data:", error);
      this.setState({ error: "Failed to load quiz data. Please try again later." });
    }
  };

  handleLanguageChange = () => {
    const { selectedLanguage } = this.state;
    // Toggle between Arabic and English
    const newLanguage = selectedLanguage === "AR" ? "EN" : "AR";
    this.setState({ selectedLanguage: newLanguage });
    this.loadQuestions(newLanguage);
  };

  handleAnswerClick = (selectedAnswer) => {
    const { currentQuestion, questions, score } = this.state;

    if (this.state.quizComplete) {
      // Reset the quiz if it's complete
      this.setState({ currentQuestion: 0, score: 0, selectedAnswer: null, quizComplete: false });
    } else {
      if (selectedAnswer === questions[currentQuestion].correct) {
        this.setState({ score: score + 1 });
      }

      this.setState({ selectedAnswer });

      // Move to the next question or complete the quiz
      setTimeout(this.moveToNextQuestion, 1000);
    }
  };

  moveToNextQuestion = () => {
    const { currentQuestion, questions } = this.state;

    if (currentQuestion < questions.length - 1) {
      this.setState({
        currentQuestion: currentQuestion + 1,
        selectedAnswer: null,
      });
    } else {
      this.setState({ quizComplete: true });
    }
  };

  render() {
    const { error, currentQuestion, questions, selectedAnswer, score, quizComplete, selectedLanguage } = this.state;

    if (error) {
      return (
        <div className="quiz-container">
          {error}
        </div>
      );
    }

    if (questions.length === 0) {
      return (
        <div className="quiz-container">
          Loading...
        </div>
      );
    }

    const question = questions[currentQuestion];

    return (
      <CSSTransition in={true} timeout={1000} classNames="quiz-container-transition">
        <div className="quiz-container">
          <div className="language-button" onClick={this.handleLanguageChange}>

            
            {selectedLanguage === "AR" ? (
              <img src="logo_lang.png" alt="AR" />
            ) : (
              <img src="logo_lang.png" alt="EN" />
            )}
          <div className="language-text">
            {selectedLanguage === "AR" ? "EN" : "AR"}
          </div>
          </div>
          {quizComplete ? (
            <div className="quiz-completion">
              <CSSTransition in={true} timeout={1000} classNames="fade">
              <div>
                <img src="done.png" alt="done image" />
                <br></br>
                <br></br>
                <h2>{selectedLanguage === "AR" ? "اكتمال الاختبار" : "Quiz Completion"}</h2>
                <p>{selectedLanguage === "AR" ? "تهانينا" : "Congratulations!"}</p>
                <p>{selectedLanguage === "AR" ? "نقاطك" : "Your Score"}: {score}/{questions.length}</p>
                <button className="retry-button" onClick={this.handleAnswerClick}>
                  {selectedLanguage === "AR" ? "اعادة المحاولة" : "RETRY"}
                </button>
              </div>
              </CSSTransition>
            </div>
          ) : (
            <div>
              <img src="sportsquiz.png" alt="Sports Quiz" />
              <h2>{question.question}</h2>
              <ul className="options">
                {question.options.map((option, index) => (
                  <CSSTransition key={index} in={true} timeout={500} classNames="fade">
                    <li
                      className={
                        selectedAnswer === option
                          ? option === question.correct
                            ? "correct"
                            : "incorrect"
                          : ""
                      }
                      onClick={() => this.handleAnswerClick(option)}
                    >
                      {option}
                    </li>
                  </CSSTransition>
                ))}
              </ul>
            </div>
          )}
        </div>
      </CSSTransition>
    );
  }
}

export default Quiz;