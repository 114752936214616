import React, { Component } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import "./LandingPage.css"; // Import a custom CSS file for the LandingPage



class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      otp: "",
      error: null,
    };
  }

  handlePhoneChange = (e) => {
    this.setState({ phoneNumber: e.target.value });
  };

  handleOTPChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  handleLogin = () => {
    if (this.state.phoneNumber === "+11111111" && this.state.otp === "0000") {
      // Redirect to the quiz component using window.location
      window.location.href = "/quiz";
    } else {
      this.setState({ error: "Wrong credentials. Please try again." });
    }
  };

  render() {
    return (
      <div className="landing-page-container"> {/* Add a custom CSS class for styling */}
        <h1>LOGIN</h1>
        <Form>
          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              value={this.state.phoneNumber}
              onChange={this.handlePhoneChange}
            />
          </Form.Group>

          <Form.Group controlId="otp">
            <Form.Label>OTP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              value={this.state.otp}    
              onChange={this.handleOTPChange}
            />
          </Form.Group>

          {this.state.error && (
            <Alert variant="danger">{this.state.error}</Alert>
          )}

          <Button
            variant="primary"
            onClick={this.handleLogin}
          >
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default LandingPage;