import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LandingPage from './LandingPage'; // Import your LandingPage component
import Quiz from './Quiz'; // Import your Quiz component

function App() {
  return (
    <Router>
         <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/quiz" element={<Quiz />} />
      </Routes>
    </Router>
  );
}

export default App;